<template>
  <div class="datasets">
    <h3>My Datasets</h3>
    <Loading :is_loading="loading" class="loading" message="Loading Datasets..."/>
    <div v-if="!loading" class="table-container">
      <b-table striped hover :items="datasets" :fields="fields" :current-page="currentPage"
               :per-page="perPage"></b-table>
      <div class="page-control">
        <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            class="flex-grow-0 w-auto">
        </b-form-select>
        <b-pagination
            v-model="currentPage"
            :total-rows="datasets.length"
            :per-page="perPage"
            size="sm"
            class=""></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DatasetService from '@/services/dataset.service';
import Loading from "../../components/Loading";

export default {
  name: 'Dashboards',
  components: {Loading},
  data() {
    return {
      datasets: [],
      loading: true,
      perPage: 25,
      pageOptions: [
        25,
        50,
        100,
        10000
      ],
      currentPage: 1,
      fields: [
        {key: 'human_readable', label: 'Name', sortable: true},
        {key: 'name', label: 'Key', sortable: true},
        {key: 'organisation', sortable: true},
        {key: 'format', sortable: true},
        {key: 'aggregation', sortable: true},
        {key: 'number_versions', label: 'Versions', sortable: true},
        {key: 'category', sortable: true},
        {
          key: 'is_public',
          sortable: true,
          formatter: (value) => {
            if (value) {
              return 'Yes';
            } else {
              return 'No';
            }
          },
        },
      ],
      search_query: {},
    };
  },
  mounted() {
    DatasetService.getAllDatasetFeatures().then(
        (response) => {
          this.datasets = response.data['datasets'];
          console.log('Datasets:');
          console.log(this.datasets);
          this.loading = false
        },
        (error) => {
          this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          this.loading = false
        }
    );
  },
};
</script>

<style scoped>

.datasets {
  padding: 40px;
  overflow: auto;
}

.table-container {

}

.page-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

</style>
